// @flow

import { combineReducers } from "redux";
import application from "./application";

// import modules
import createInspection from "./create-inspection";
import reasons from "./reasons";
import addReason from "./add-reason";
import removeReason from "./remove-reason";
import updateReason from "./update-reason";
import deleteReason from "./delete-reason";
import inspectionPoints from "./inspection-points";
import submitInspection from "./submit-inspection";
import inspection from "./inspection";
import persons from "./persons";
import personsForIp from "./persons-for-ip";
import deleteModule from "./delete-module";
import widgets from "./widgets";
import organisationStructure from "./organisation-structure";
import deleteAllModule from "./delete-all-modules";
import openUpModulesAgain from "./make-forms-editable";

// assignments
import assignments from "./assignments";
import watchedAssignments from "./watched-assignments";
import assignmentsValues from "./assignmentsValues";
import reportTypes from "./report-types";
import reportTypesValues from "./report-types-values";
import addModule from "./add-module";
import modules from "./modules";
import addInspectors from "./add-inspectors";
import removeInspectors from "./remove-inspectors";
import addWatcher from "./add-watcher";
import deleteWatcher from "./delete-watcher";
import leadInspector from "./lead-inspector";
import addReviewer from "./add-reviewer";
import downloadDraftReport from "./download-draft-report";
import updateAssignmentStatus from "./update-assignment-status";
import modulePdf from "./module-pdf";
import saveReviewFeedback from "./save-reviewfeedback";
import createDraftReport from "./create-draft-report";
import addModuleInspector from "./add-module-inspector";
import updateModuleInspector from "./update-module-inspector";
import removeModuleInspector from "./remove-module-inspector";
import addReaction from "./add-reaction";
import deleteReaction from "./delete-reaction";
import reactions from "./reactions";
import getReactions from "./get-reactions";
import getWatchers from "./get-watchers";
import updateReaction from "./update-reaction";
import processReaction from "./process-reaction";
import visitModules from "./visit-modules";
import reopenAssignment from "./reopen-assignment";
import postponeAssignment from "./postpone-assignment";
import markReportAsReady from "./mark-report-as-ready";
import declineDraftReport from "./decline-draft-report";
import declineFinalReport from "./decline-final-report";
import previewModulePdf from "./preview-module-pdf";
import createFinalReport from "./create-draft-report";
import addReportItem from "./add-reportItem";
import getReportsForAssignment from "./get-reports-for-assignment";
import deleteReport from "./delete-report";
import reportItems from "./reportItems";
import draftChanges from "./draft-changes";
import getTeams from "./get-teams";
import previewAnonymizedReport from "./preview-anonymized-report";
import addVisit from "./add-visit";
import updateVisit from "./update-visit";
import updateMainVisit from "./update-main-visit";
import removeVisitAddress from "./remove-visit-address";
import addVisitPeriod from "./add-visit-period";
import updateVisitPeriod from "./update-visit-period";
import removeVisitPeriod from "./remove-visit-period";
import removeVisit from "./remove-visit";
import addContactPersons from "./add-contact-persons";
import getContactPersons from "./get-contact-persons";
import deleteContactPerson from "./delete-contact-person";
import allowDeadlineExtension from "./allow-deadline-extension";
import unlinkRequest from "./unlink-request";
import linkRequests from "./link-requests";
import addInspectionReason from "./add-inspection-reason";
import contactPersonsValue from "./contactPersonsValue";
import endReactionPeriod from "./end-reaction-period";
import updateReviewer from "./update-reviewer";
import history from "./history";
import updateLastObservation from "./update-last-observation";
import labels from "./labels";
import changeLabel from "./change-label";
import addLabel from "./add-label";
import removeLabel from "./remove-label";
import updateDisclosureLevel from "./update-disclosure-level";
import publishReport from "./publish-report";
import suppressReport from "./suppress-report";
import reactionsAllowed from "./reactions-allowed";
import updateTargetDate from "./update-target-date";
import updateInspection from "./update-inspection";
import addInspectionInstitutionType from "./add-inspection-institutiontype";
import removeInspectionInstitutionType from "./remove-inspection-institutiontype";
import updateInspectionMainInstitutionType from "./update-inspection-maininstitutiontype";
import overrideDraftReport from "./override-draft-report";
import overrideFinalReport from "./override-final-report";
import inspectionAdditionalDashboardInfo from "./inspection-additional-dashboard-info";
import updateExternalReference from "./update-external-reference";
import assignToMe from "./assign-to-me";
import getInterlocutors from "./get-interlocutors";
import addInterlocutor from "./add-interlocutor";
import deleteInterlocutor from "./delete-interlocutor";
import updateInterlocutor from "./update-interlocutor";
import updateInspectionTeam from "./update-inspection-team";

//IPD
import downloadInbreukenCSV from "./download-inbreuken-csv";
import inspectionPointDetails from "./inspection-point-details";
import inspectionPointParent from "./inspection-point-parent";
import closeInspection from "./close-inspection";
import inspectionPointHistory from "./inspection-point-history";
import updateShortcoming from "./update-shortcoming";
import shortcomings from "./shortcomings";
import concerns from "./concerns";
import shortcoming from "./shortcoming";
import exemptions from "./exemptions";
import addExemption from "./add-exemption";
import updateExemption from "./update-exemption";
import inspectionPointFlags from "./inspectionpoint-flags";
import updateInspectionPointFlag from "./update-inspectionpoint-flag";
import removeInspectionPointFlag from "./remove-inspectionpoint-flag";
import additionalPropertiesForInspectionPoint from "./additional-properties-for-inspection-point";
import getPersonInfo from "./get-person-info-vaph";
import getAdditionalInformationForInspectionPoint from "./get-additional-information-for-IP";
import addAdditionalInformationForInspectionPoint from "./add-additional-information-for-IP";
import updateAdditionalInformationForInspectionPoint from "./update-additional-information-for-IP";
import deleteAdditionalInformationForInspectionPoint from "./delete-additional-information-for-IP";

// Requests
import createRequest from "./create-request";
import requests from "./requests";
import requestsForInspectionPoint from "./requests-for-inspectionpoint";
import request from "./request";
import submitRequest from "./submit-request";
import requestValues from "./requestValues";
import updateRequest from "./update-request";
import deleteRequestAttachment from "./delete-request-attachment";
import getFunctionalEntities from "./get-functional-entities";
import inspectionForRequests from "./inspection-for-requests";
import getFeUsers from "./get-functional-entity-users";
import cancelAssignment from "./cancel-assignment";
import updateRequestAssignee from "./update-request-assignee";
import updateRequestValidator from "./update-request-validator";
import cancelRequest from "./cancel-request";
import requestsForInspection from "./requests-for-inspection";
import requestHistory from "./request-history";
import uploadRequestAttachment from "./upload-request-attachment";
import { updateRequestMainVisit } from "./update-request-main-visit";
import addRequestVisit from "./add-request-visit";
import removeRequestVisit from "./remove-request-visit";

//notifications
import notifications from "./notifications";

//Documents
import uploadDocuments from "./upload-documents";
import uploaderValue from "./uploader-value";
import downloadDocument from "./download-document";
import deleteReactionAttachments from "./delete-reaction-attachments";

// auth
import user from "./user";
import userInfo from "./user-info";

// templates
import notificationTemplates from "./notification-templates";
import notificationTemplate from "./notification-template";
import sendNotification from "./send-notification";
import getNotificationById from "./notifications-by-notificationId";
import getNotificationForInspection from "./notifications-for-inspection";
import addTemplate from "./add-template";
import editTemplate from "./edit-template";
import deleteTemplate from "./delete-template";
import institutionTypeGroups from "./institution-type-groups";
import activityTypes from "./activity-types";
import institutionTypes from "./institution-types";
import inspectionPointStructure from "./inspectionpoint-structure";
import inspectionPointChildren from "./inspectionpoint-children";
import inspectionPointsActivitytypes from "./inspection-point-activitytypes";
import inspectionPointsInstitutionTypes from "./inspection-point-institutiontypes";
import treeValue from "./tree-value";

// notes
import notes from "./notes";
import addNote from "./add-note";
import deleteNote from "./delete-note";
import inspectionPointNotes from "./inspection-point-notes";
import addInspectionPointNote from "./add-inspection-point-note";
import deleteInspectionPointNote from "./delete-inspection-point-note";

// Advanced Search
import advancedSearchAssignments from "./advanced-search-assignments";
import advancedSearchRequests from "./advanced-search-requests";

// deadline extension reasons
import deadlineExtensionReasons from "./deadline-extension-reasons";
import editDeadlineExtensionReason from "./edit-deadline-extension-reason";
import deleteDeadlineExtensionReason from "./delete-deadline-extension-reason";
import addDeadlineExtensionReason from "./add-deadline-extension-reason";
// admin
import inspectionPointsModular from "./inspection-points-modular";
import createInspectionPoint from "./create-inspection-point";
import linkInspectionPoint from "./link-inspection-point";
import updateInspectionPoint from "./update-inspection-point";
import addRegionalDistribution from "./add-regional-distribution";
import getRegionalDistribution from "./get-regional-distribution";
import deleteRegionalDistribution from "./delete-regional-distribution";
import uploadBulkAssignment from "./upload-bulk-assignment";
import addInstitutionTypeReference from "./add-institution-type-reference";
import addInstitutionTypeReferenceEmail from "./add-institution-type-reference-email";
import institutionTypeReferences from "./institution-type-references";
import removeInstitutionTypeReferenceEmail from "./remove-institution-type-reference-email";
import updateInstitutionTypeReferenceGroup from "./update-institution-type-reference-group";
import updateInstitutionTypeReferenceTeam from "./update-institution-type-reference-team";
import updateRegionalDistribution from "./update-regional-distribution";

import uploadImageAttachment from "./upload-image-attachment";
import updateModuleProperty from "./update-module-property";
import startReactionPeriod from "./start-reaction-period";

import inactivityDates from "./inactivity-dates";
import addInactivityDate from "./add-inactivity-date";
import removeInactivityDate from "./remove-inactivity-date";

import addCustomQuery from "./add-custom-query";
import updateCustomQuery from "./update-custom-query";
import deleteCustomQuery from "./delete-custom-query";
import getCustomQueries from "./get-custom-queries";

import addReportTitle from "./add-report-title";
import reportTitles from "./report-titles";
import removeReportTitle from "./remove-report-title";

import getDisclosureLevelsParams from "./get-disclosure-levels-params";
import addDisclosureLevelsParams from "./add-disclosure-levels-params";
import updateDisclosureLevelsParams from "./update-disclosure-levels-params";
import deleteDisclosureLevelsParams from "./delete-disclosure-levels-params";

import juridicalForms from "./juridical-forms";
import countRequests from "./count-requests";
import countRequestsActive from "./count-requests-active";
import countInspectionsActive from "./count-inspections-active";
import countInspectionsAssigned from "./count-inspections-assigned";
import countInspectionsWatched from "./count-inspections-watched";

import formsSummary from "./forms-summary";
import formsScheduleActivation from "./forms-schedule-activation";
import formsScheduleDeactivation from "./forms-schedule-deactivation";
import formsScheduleCancel from "./forms-schedule-cancel";
import formsImport from "./forms-import";

import getFormsForInspection from "./get-forms-for-inspection";
import getFormsVersionsForInspection from "./get-forms-versions-for-inspection";
import getFormsWithVersion from "./get-forms-with-version";

import getInternalGroups from "./get-internal-groups";
import createInternalGroup from "./create-internal-group";
import deleteInternalGroup from "./delete-internal-group";
import updateInternalGroup from "./update-internal-group";
import addInternalGroupEmail from "./add-internal-group-email";
import removeInternalGroupEmail from "./remove-internal-group-email";
import addInternalGroupMembers from "./add-internal-group-members";
import removeInternalGroupMember from "./remove-internal-group-member";
import advancedSearchPersons from "./advanced-search-persons";

import getExternalGroups from "./get-external-groups";
import createExternalGroup from "./create-external-group";
import updateExternalGroup from "./update-external-group";
import deleteExternalGroup from "./delete-external-group";
import removeExternalGroupEmail from "./remove-external-group-email";
import addExternalGroupEmail from "./add-external-group-email";
import addExternalGroupMembers from "./add-external-group-members";
import removeExternalGroupMember from "./remove-external-group-member";

import getClusters from "./get-clusters";
import createCluster from "./create-cluster";
import deleteCluster from "./delete-cluster";
import updateCluster from "./update-cluster";
import addClusterMembers from "./add-cluster-members";
import removeClusterMember from "./remove-cluster-member";
import advancedSearchFeUsers from "./advanced-search-fe-users";
import updatePerson from "./update-person";
import updatePersonsTeam from "./update-persons-team";
import updateFeUsers from "./update-fe-users";

import getAccountsCurrent from "./get-accounts-current";

/**
 * The root reducer
 */
export default combineReducers<*, *>({
    activityTypes,
    addClusterMembers,
    addContactPersons,
    addCustomQuery,
    addDeadlineExtensionReason,
    addDisclosureLevelsParams,
    addExternalGroupMembers,
    addInspectionPointNote,
    addExemption,
    addExternalGroupEmail,
    addInactivityDate,
    addInspectionInstitutionType,
    addInspectionReason,
    addInspectors,
    addInstitutionTypeReference,
    addInstitutionTypeReferenceEmail,
    addInternalGroupEmail,
    addInternalGroupMembers,
    addInterlocutor,
    additionalPropertiesForInspectionPoint,
    addAdditionalInformationForInspectionPoint,
    addLabel,
    addModule,
    addModuleInspector,
    addNote,
    addRegionalDistribution,
    addReportTitle,
    addReaction,
    addReason,
    addReportItem,
    addRequestVisit,
    addReviewer,
    addTemplate,
    addVisit,
    addVisitPeriod,
    addWatcher,
    advancedSearchAssignments,
    advancedSearchFeUsers,
    advancedSearchPersons,
    advancedSearchRequests,
    allowDeadlineExtension,
    application,
    assignments,
    assignmentsValues,
    assignToMe,
    cancelAssignment,
    cancelRequest,
    changeLabel,
    closeInspection,
    concerns,
    contactPersonsValue,
    countInspectionsActive,
    countInspectionsAssigned,
    countInspectionsWatched,
    countRequests,
    countRequestsActive,
    createCluster,
    createDraftReport,
    createExternalGroup,
    createFinalReport,
    createInspection,
    createInspectionPoint,
    createInternalGroup,
    createRequest,
    deadlineExtensionReasons,
    declineDraftReport,
    declineFinalReport,
    deleteAllModule,
    deleteAdditionalInformationForInspectionPoint,
    deleteCluster,
    deleteContactPerson,
    deleteCustomQuery,
    deleteDeadlineExtensionReason,
    deleteDisclosureLevelsParams,
    deleteExternalGroup,
    deleteInterlocutor,
    deleteInternalGroup,
    deleteModule,
    deleteNote,
    deleteInspectionPointNote,
    deleteReason,
    deleteReactionAttachments,
    deleteReaction,
    deleteRegionalDistribution,
    deleteReport,
    deleteRequestAttachment,
    deleteTemplate,
    deleteWatcher,
    downloadDocument,
    downloadDraftReport,
    downloadInbreukenCSV,
    editDeadlineExtensionReason,
    endReactionPeriod,
    draftChanges,
    editTemplate,
    exemptions,
    formsImport,
    formsScheduleActivation,
    formsScheduleCancel,
    formsScheduleDeactivation,
    formsSummary,
    getAccountsCurrent,
    getAdditionalInformationForInspectionPoint,
    getClusters,
    getContactPersons,
    getCustomQueries,
    getDisclosureLevelsParams,
    getFeUsers,
    getFormsForInspection,
    getFormsVersionsForInspection,
    getFormsWithVersion,
    getFunctionalEntities,
    getInterlocutors,
    juridicalForms,
    getExternalGroups,
    getInternalGroups,
    getNotificationById,
    getNotificationForInspection,
    getPersonInfo,
    getReactions,
    getRegionalDistribution,
    getReportsForAssignment,
    getTeams,
    getWatchers,
    history,
    inactivityDates,
    inspection,
    inspectionAdditionalDashboardInfo,
    inspectionForRequests,
    inspectionPointChildren,
    inspectionPointDetails,
    inspectionPointFlags,
    inspectionPointHistory,
    inspectionPointNotes,
    inspectionPointParent,
    inspectionPoints,
    inspectionPointsActivitytypes,
    inspectionPointsInstitutionTypes,
    inspectionPointsModular,
    inspectionPointStructure,
    institutionTypeGroups,
    institutionTypeReferences,
    institutionTypes,
    labels,
    leadInspector,
    linkInspectionPoint,
    linkRequests,
    markReportAsReady,
    modulePdf,
    modules,
    notes,
    notifications,
    notificationTemplate,
    notificationTemplates,
    openUpModulesAgain,
    organisationStructure,
    overrideDraftReport,
    overrideFinalReport,
    persons,
    personsForIp,
    postponeAssignment,
    previewAnonymizedReport,
    previewModulePdf,
    processReaction,
    publishReport,
    reactions,
    reactionsAllowed,
    reasons,
    removeClusterMember,
    removeExternalGroupEmail,
    removeExternalGroupMember,
    removeInactivityDate,
    removeInspectionInstitutionType,
    removeInspectionPointFlag,
    removeInspectors,
    removeInstitutionTypeReferenceEmail,
    removeInternalGroupEmail,
    removeInternalGroupMember,
    removeLabel,
    removeModuleInspector,
    removeReason,
    removeReportTitle,
    removeRequestVisit,
    removeVisit,
    removeVisitAddress,
    removeVisitPeriod,
    reopenAssignment,
    reportItems,
    reportTitles,
    reportTypes,
    reportTypesValues,
    request,
    requestHistory,
    requests,
    requestsForInspection,
    requestsForInspectionPoint,
    requestValues,
    saveReviewFeedback,
    sendNotification,
    shortcoming,
    shortcomings,
    submitInspection,
    submitRequest,
    suppressReport,
    treeValue,
    updateAssignmentStatus,
    updateAdditionalInformationForInspectionPoint,
    updateCluster,
    updateCustomQuery,
    updateDisclosureLevel,
    updateDisclosureLevelsParams,
    updateExemption,
    updateExternalGroup,
    updateFeUsers,
    updateInspection,
    updateInspectionMainInstitutionType,
    updateInspectionPoint,
    updateInspectionPointFlag,
    updateInspectionTeam,
    updateInstitutionTypeReferenceGroup,
    updateInstitutionTypeReferenceTeam,
    updateInternalGroup,
    updateInterlocutor,
    updateLastObservation,
    updateMainVisit,
    updateModuleInspector,
    updateModuleProperty,
    updatePerson,
    updatePersonsTeam,
    updateReaction,
    updateReason,
    updateRegionalDistribution,
    updateRequest,
    updateRequestAssignee,
    updateRequestMainVisit,
    updateRequestValidator,
    updateReviewer,
    updateShortcoming,
    updateTargetDate,
    updateVisit,
    updateVisitPeriod,
    uploadBulkAssignment,
    updateExternalReference,
    uploadDocuments,
    uploadImageAttachment,
    uploadRequestAttachment,
    uploaderValue,
    unlinkRequest,
    user,
    userInfo,
    visitModules,
    watchedAssignments,
    widgets,
    startReactionPeriod,
});
